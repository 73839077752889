import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About";
import Departments from "../views/Departments";
import News from "../views/News";
import Contact from "../views/Contact";
import Definition from "../views/Definition.vue";
import Types from "../views/TypesOfStroke.vue";
import Symptoms from "../views/Symptoms.vue";
import Risks from "../views/RiskFactors.vue";
// import Reviews from "../views/Reviews";
import Appointment from "../views/Appointment";
import Doctors from "../views/Doctors";
import TermsOfUse from "../views/TermsOfUse";
import PrivacyPolicy from "../views/PrivacyPolicy";
import SelectedLatestNewsDetail from "../components/SelectedLatestNewsDetail";
import SelectedDepartmentDetail from "../components/SelectedDepartmentDetail";

Vue.use(VueRouter);
// Vue.prototype.$scrollToTop = () =>{
//   window.scrollTo(0, 0)
// }
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about-us",
    name: "About",
    component: About,
  },
  {
    path: "/departments",
    name: "Departmnet",
    component: Departments,
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/department/:id",
    name: "SelectedDepartmentDetail",
    component: SelectedDepartmentDetail,
  },
  {
    path: "/news/:id",
    name: "SelectedLatestNewsDetail",
    component: SelectedLatestNewsDetail,
  },
  {
    path: "/contact-us",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/definition",
    name: "Definition",
    component: Definition,
  },
  {
    path: "/types-of-stroke",
    name: "Types",
    component: Types,
  },
  {
    path: "/symptoms",
    name: "Symptoms",
    component: Symptoms,
  },
  {
    path: "/risk-factors",
    name: "Risks",
    component: Risks,
  },

  // {
  //   path: "/reviews",
  //   name: "Review",
  //   component: Reviews,
  // },
  {
    path: "/appointment",
    name: "Appointment",
    component: Appointment,
  },
  {
    path: "/doctors",
    name: "Doctors",
    component: Doctors,
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: TermsOfUse,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
