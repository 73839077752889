<template>
  <div class="max-w-6xl mx-auto text-gray-600">
    <section class="px-5 py-20">
      <div class="w-full mb-10 lg:w-1/2">
        <h1 class="mb-4 text-3xl font-black text-axon-blue">
          {{ $t("affiliate-companies") }}
        </h1>
        <div class="w-20 h-1 rounded-lg"></div>
      </div>
      <div
        class="grid grid-cols-1 gap-5 mx-auto bg-gray-100 rounded-lg sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3"
      >
        <div
          class="flex items-center justify-center h-64"
          v-for="item in items"
          :key="item.id"
        >
          <a :href="item.url" target="_blank">
            <img
              class="object-cover object-center w-36"
              :alt="item.url"
              :src="getBaseUrl() + item.image.url"
            />
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "Affiliate",
  data() {
    return {
      items: [],
      loading: true,
      error: null,
    };
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/affiliates`);
      this.items = response.data;
      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style scoped></style>
