var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col pb-10 mx-auto mb-10 border-b border-gray-200 sm:flex-row"},[_c('div',{staticClass:"inline-flex justify-center flex-shrink-0 sm:w-48 sm:h-48 sm:mr-8"},[_c('img',{staticClass:"object-cover object-center w-full h-full rounded-lg",attrs:{"src":_vm.baseUrl + _vm.news.newsImage.url,"alt":""}})]),_c('div',{staticClass:"flex-grow mt-6 text-left sm:mt-0"},[_c('h2',{staticClass:"text-xl font-semibold text-gray-900"},[_vm._v(" "+_vm._s(_vm.news.newsTitle)+" ")]),_c('span',{staticClass:"text-sm text-gray-400"},[_vm._v(_vm._s(_vm.news.publishedDate))]),_c('p',{staticClass:"mt-3",class:{
        'line-clamp-4':
          _vm.news.newsContent.trim().split(/\s+/).length > 60 ? true : false,
      }},[_c('VueShowdown',{attrs:{"markdown":_vm.news.newsContent}})],1),(_vm.news.newsSource)?_c('p',{staticClass:"mt-2"},[_c('span',{staticClass:"italic font-medium"},[_vm._v("Source link: ")]),_c('a',{staticClass:"border-b border-transparent hover:text-axon-red hover:border-axon-red",attrs:{"href":_vm.news.newsSource,"target":"_blank"}},[_vm._v(_vm._s(_vm.news.newsSource))])]):_vm._e(),(_vm.news.newsContent.trim().split(/\s+/).length > 60 ? true : false)?_c('router-link',{staticClass:"inline-flex items-center mt-3 text-sm transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-axon-red hover:border-axon-red",attrs:{"to":{
        name: 'SelectedLatestNewsDetail',
        params: {
          news: _vm.news,
          id: _vm.news.id,
        },
      }},nativeOn:{"click":function($event){return _vm.scrollToTop.apply(null, arguments)}}},[_vm._v(" Read More "),_c('svg',{staticClass:"w-4 h-4 ml-1",attrs:{"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M5 12h14M12 5l7 7-7 7"}})])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }