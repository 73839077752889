var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"max-w-6xl mx-auto text-gray-600"},[_c('div',{staticClass:"px-5 py-20"},[_c('div',{staticClass:"flex flex-wrap w-full mb-10"},[_c('div',{staticClass:"w-full mb-6 lg:w-1/2 lg:mb-0"},[_c('h1',{staticClass:"mb-4 text-3xl font-black text-axon-blue"},[_vm._v(" "+_vm._s(_vm.$t("services"))+" ")]),_c('div',{staticClass:"w-20 h-1 rounded bg-primary-green"})])]),(!_vm.loading)?_c('div',[_c('div',{staticClass:"grid grid-cols-1 gap-8 pb-20 mx-auto sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2"},_vm._l((_vm.items),function(department){return _c('div',{key:department.id,staticClass:"overflow-hidden text-gray-600 bg-gray-100 rounded-lg"},[_c('div',{staticClass:"relative pb-2/3"},[_c('img',{staticClass:"absolute object-cover object-center w-full h-full",attrs:{"src":_vm.getBaseUrl() + department.departmentImage.url,"alt":""}})]),_c('div',{staticClass:"px-4 py-4"},[_c('div',{staticClass:"mb-2 text-xl font-bold"},[_vm._v(" "+_vm._s(department.title)+" ")]),_c('p',{staticClass:"leading-relaxed tracking-wide text-gray-600",class:{
                'line-clamp-5':
                  department.departmentContent.trim().split(/\s+/).length > 36
                    ? true
                    : false,
              }},[_c('VueShowdown',{attrs:{"markdown":department.departmentContent}})],1),(
                department.departmentContent.trim().split(/\s+/).length > 36
              )?_c('router-link',{staticClass:"inline-flex items-center mt-3 text-sm transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-axon-red hover:border-axon-red",attrs:{"to":{
                name: 'SelectedDepartmentDetail',
                params: {
                  department: department,
                  id: department.id,
                },
              }},nativeOn:{"click":function($event){return _vm.scrollToTop.apply(null, arguments)}}},[_vm._v(" ... "+_vm._s(_vm.$t("read-more"))+" ")]):_vm._e()],1)])}),0)]):_c('div',[_c('img',{staticClass:"w-64 m-auto",attrs:{"src":_vm.loadingImage}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }