<template>
  <div>
    <nav class="px-5 pt-1 mx-auto bg-gray-100 text-axon-blue">
      <div>
        <div class="flex items-center justify-between">
          <img src="@/assets/logo.svg" alt="Axon logo" class="h-14 md:h-20" />
          <LocaleSwitcher />
          <button
            @click="toggle"
            class="px-3 py-1 border border-gray-400 border-solid rounded opacity-70 hover:opacity-75 lg:hidden"
            id="navbar-toggle"
          >
            <svg
              fill="none"
              stroke="currentColor"
              class="w-5 h-5 md:w-6 md:h-6"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
        <div class="-mx-5">
          <div
            :class="open ? 'flex' : 'hidden'"
            class="flex-col items-center justify-end px-5 py-2 mt-3 space-x-10 space-y-3 text-white bg-axon-blue lg:space-y-0 lg:flex lg:flex-row lg:ml-auto lg:mt-0"
            id="navbar-collapse"
          >
            <router-link
              v-for="nav in navs1"
              :key="nav.name"
              @click.native="scrollToTop"
              :to="nav.route"
              class="py-2 font-bold transition-colors duration-300 md:mx-2 hover:opacity-70"
            >
              {{ $t(`nav.${nav.name}`) }}
            </router-link>
            <div class="relative inline-block dropdown">
              <button
                class="inline-flex items-center px-4 py-2 transition-colors duration-300 rounded lg:px-3 md:mx-2"
              >
                <span class="mr-1 font-bold">{{ $t("nav.about-stroke") }}</span>
                <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </button>
              <ul class="absolute hidden pt-1 font-bold dropdown-menu">
                <router-link
                  v-for="dropdown in dropdown"
                  :key="dropdown.name"
                  @click.native="scrollToTop"
                  :to="dropdown.route"
                  class="block px-4 py-2 text-black whitespace-no-wrap bg-white lg:text-white lg:bg-axon-blue hover:bg-axon-black hover:text-white"
                >
                  {{ $t(`about-stroke.${dropdown.name}`) }}
                </router-link>
              </ul>
            </div>
            <router-link
              v-for="nav in navs2"
              :key="nav.name"
              @click.native="scrollToTop"
              :to="nav.route"
              class="py-2 font-bold transition-colors duration-300 md:mx-4 hover:opacity-70"
            >
              {{ $t(`nav.${nav.name}`) }}
            </router-link>
            <router-link
              id="appointment"
              @click.native="scrollToTop"
              to="/appointment"
              class="w-64 p-2 text-center uppercase transition-colors duration-300 border rounded-full md:w-auto lg:px-3 border-axon-red hover:opacity-80 bg-axon-red"
            >
              {{ $t("nav.make-appointment") }}
            </router-link>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import LocaleSwitcher from "../LocaleSwitcher.vue";
export default {
  name: "Header",
  components: { LocaleSwitcher },
  data() {
    return {
      navs1: [
        {
          name: "home",
          route: "/",
        },
      ],
      navs2: [
        {
          name: "services",
          route: "/departments",
        },
        {
          name: "doctors",
          route: "/doctors",
        },
        {
          name: "news",
          route: "/news",
        },
        {
          name: "about-us",
          route: "/about-us",
        },
        {
          name: "contact-us",
          route: "/contact-us",
        },
      ],
      dropdown: [
        {
          name: "definition",
          route: "/definition",
        },
        {
          name: "types",
          route: "/types-of-stroke",
        },
        {
          name: "symptoms",
          route: "/symptoms",
        },
        {
          name: "risk-factors",
          route: "/risk-factors",
        },
      ],
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
#appointment {
  color: #fff !important;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
/* .nav-items {
} */
</style>
