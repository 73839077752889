<template>
  <section>
    <div class="hero">
      <div
        class="left-0 flex flex-col items-center justify-center h-full text-gray-200 bg-black bg-opacity-40"
      >
        <div class="p-4 mx-8 text-center">
          <p class="text-2xl font-bold text-gray-200 md:text-5xl">
            {{ symptoms.title }}
          </p>
        </div>
      </div>
    </div>
    <div class="container max-w-4xl px-4 py-16 mx-auto">
      <h1 class="mb-4 text-4xl font-black text-gray-800">
        {{ symptoms.subTitle }}
      </h1>
      <div class="p-10 my-10 rounded bg-indigo-50">
        <p class="leading-relaxed tracking-wide text-gray-600">
          <VueShowdown :markdown="`${symptoms.content}`" />
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Symptoms",
  data() {
    return {
      symptoms: {},
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiSymptoms",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.symptoms = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss" scoped>
.hero {
  background-image: url("~@/assets/images/hero/Stroke.jpeg");
  object-fit: cover;
  background-repeat: no-repeat;
  height: 500px;
  z-index: 2;
  background-size: cover;
  background-position: center;
  position: relative;
}
</style>
