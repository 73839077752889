<template>
  <div class="max-w-6xl mx-auto text-gray-600">
    <section class="px-5 py-20">
      <div class="w-full mb-10 lg:w-1/2">
        <h1 class="mb-4 text-3xl font-black text-axon-blue">
          {{ $t("our-history") }}
        </h1>
      </div>
      <div class="grid gap-10 lg:grid-cols-2">
        <div class="order-1">
          <p class="mb-5 leading-relaxed tracking-wide">
            <VueShowdown :markdown="`${history.Content}`" />
          </p>
        </div>
        <div class="relative pb-2/3">
          <img
            alt="history"
            :src="getBaseUrl() + history.Image.url"
            class="absolute object-cover w-full h-full rounded-lg"
          />
        </div>
      </div>
      <!-- <div class="grid items-center grid-cols-1 mb-24 md:grid-cols-2 gap-x-10">
        <div class="order-1">
          <p class="mb-5 leading-relaxed tracking-wide">
            <VueShowdown :markdown="`${history.Content}`" />
          </p>
        </div>
        <div class="flex items-center justify-center w-full h-full">
          <img
            alt="history"
            :src="getBaseUrl() + history.Image.url"
            class="rounded-lg"
          />
        </div>
      </div> -->
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "History",
  data() {
    return {
      history: {},
      loading: true,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiHistory",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    async getData() {
      try {
        const history = await axios.get(this.api);
        this.history = history.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
