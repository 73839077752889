<template>
  <section class="2xl:container 2xl:mx-auto">
    <!-- <slider
      animation="fade"
      :indicators="false"
      :interval="8000"
      :speed="1000"
      :stopOnHover="true"
      :height="479 + 'px'"
    >
      <slider-item
        class="bg-gray-100"
        v-for="item in items"
        :key="item.id"
        :style="{
          backgroundImage: 'url(' + getBaseUrl() + item.sliderImage.url + ')',
          'object fit': 'cover',
          'background-position': 'left',
          'background-repeat': 'no-repeat',
          'z-index': '2',
          'background-size': 'cover',
        }"
      >
        <div
          class="left-0 flex flex-col items-center justify-end h-full text-gray-200 lg:hidden gradient-bg"
        >
          <div class="w-full">
            <div class="py-4 text-center">
              <p class="text-3xl font-black">
                {{ item.sliderTitle }}
              </p>
              <p>{{ item.sliderContent }}</p>
            </div>
          </div>
        </div>
      </slider-item>
    </slider> -->
    <!-- :arrows="false" -->
    <vueper-slides
      bullets-outside
      :dragging-distance="50"
      progress
      autoplay
      :pause-on-hover="true"
    >
      <!-- :alt="item.Title"
        :title="item.Title" -->
      <vueper-slide
        v-for="(item, i) in items"
        :key="i"
        :image="getBaseUrl() + item.sliderImage.url"
      >
        <template #content>
          <img :src="getBaseUrl() + item.sliderImage.url" alt="" />
        </template>
        <template #loader>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style="
              margin: auto;
              background: 0 0;
              display: block;
              shape-rendering: auto;
            "
            width="120"
            height="120"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.8888888888888888s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(40 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.7777777777777778s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(80 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.6666666666666666s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(120 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.5555555555555556s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(160 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.4444444444444444s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(200 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.3333333333333333s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(240 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.2222222222222222s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(280 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.1111111111111111s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(320 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="0s"
                repeatCount="indefinite"
              />
            </rect>
          </svg>
          <!-- <span>Loading...</span> -->
        </template>
      </vueper-slide>
    </vueper-slides>

    <!-- <div class="mx-auto max-w-7xl">
      <div class="relative bg-red-500 pb-1/3">
        <img
          :src="getBaseUrl() + items[1].sliderImage.url"
          alt=""
          class="absolute object-cover w-full h-full"
        />
      </div>
    </div> -->
  </section>
</template>

<script>
import axios from "axios";
// import { Slider, SliderItem } from "vue-easy-slider";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

//
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "Hero",
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiHeros",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.items = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss">
.vueperslides__fractions {
  border: 1px solid #044886;
  color: #044886;
}
.vueperslides__arrows {
  color: #044886;
}
// .slider-indicators {
//   z-index: 10 !important;
// }
// .slider-indicator-icon {
//   display: inline-block;
//   width: 15px !important;
//   height: 15px !important;
//   margin: 0 0.1rem;
//   cursor: pointer;
//   border-radius: 50%;
//   background-color: rgba(0, 0, 0, 0.2);
//   border: 1px solid #fff;
// }
// .slider-indicator-active {
//   background-color: #d76738 !important;
// }
// // .slider-btn-left, .slider-btn-right {
// //    background-color: red !important;
// // }
// .slider-btn {
//   z-index: 10 !important;
//   height: 50px !important;
//   top: 50% !important;
//   transform: translateY(-50%);
//   background: rgba(4, 71, 134, 0.6) !important;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .slider-btn-left {
//   margin-left: 10px !important;
// }
// .slider-btn-right {
//   margin-right: 10px !important;
// }
// .slider-icon {
//   width: 20px !important;
//   height: 20px !important;
//   font-size: bold;
// }
// .slider-icon-left {
//   margin-left: 10px !important;
//   border-bottom-left-radius: 4px;
// }
// .slider-icon-right {
//   margin-right: 10px !important;
//   border-bottom-left-radius: 4px;
// }
// .capition {
//   transform: skew(-20deg);
// }
// .capition-text {
//   transform: skew(20deg);
// }
// .gradient-bg {
//   background-image: linear-gradient(
//     180deg,
//     hsla(0, 0%, 35.29%, 0) 0%,
//     hsla(0, 0%, 34.53%, 0.034375) 16.36%,
//     hsla(0, 0%, 32.42%, 0.125) 33.34%,
//     hsla(0, 0%, 29.18%, 0.253125) 50.1%,
//     hsla(0, 0%, 24.96%, 0.4) 65.75%,
//     hsla(0, 0%, 19.85%, 0.546875) 79.43%,
//     hsla(0, 0%, 13.95%, 0.675) 90.28%,
//     hsla(0, 0%, 7.32%, 0.765625) 97.43%,
//     hsla(0, 0%, 0%, 0.8) 100%
//   );
// }
</style>
