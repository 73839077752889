<template>
  <div class="max-w-6xl px-5 py-20 mx-auto text-gray-700">
    <div class="flex flex-wrap w-full mb-14">
      <div class="w-full mb-6 lg:w-1/2 lg:mb-0">
        <h1 class="mb-4 text-3xl font-black text-axon-blue">
          {{ $t("our-team") }}
        </h1>
        <div class="w-20 h-1 rounded bg-primary-green"></div>
      </div>
    </div>
    <div v-if="!loading">
      <div class="grid mb-16" v-for="doctor in items" :key="doctor.id">
        <div>
          <img
            alt=""
            class="object-cover mb-6 rounded-lg md:mb-0 md:float-left md:mr-6 md:h-72 w-96 md:w-56"
            :src="doctor.profilePicture"
          />
          <div>
            <h2 class="text-3xl font-bold text-gray-900">
              Dr. {{ doctor.firstName }} {{ doctor.lastName }}
            </h2>
            <h3 class="mb-3 font-semibold">
              {{ doctor.specialization }}
            </h3>
            <div class="mb-4 leading-relaxed tracking-wide text-gray-600">
              <VueShowdown
                :markdown="doctor.bio !== undefined ? doctor.bio : ''"
              />
            </div>
          </div>
        </div>
      </div>
      <Consultants />
    </div>
    <div v-else>
      <img :src="loadingImage" class="w-64 m-auto" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";
import Consultants from "../components/Consultants.vue";

export default {
  name: "Doctors",
  components: {
    Consultants,
  },
  data() {
    return {
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiDoctors",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    async getData() {
      try {
        const response = await axios.get(
          "https://www.ethiopiastroke.com/api/doctor/available-doctors"
        );
        let sorted = response.data.sort((a, b) => a.rank - b.rank);
        this.items = response.data.filter((d) => {
          return d.role === "staff";
        });
        console.log("doctors", this.items);
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style>
.shape {
  shape-outside: circle(50%);
  clear: both;
  float: left;
}
</style>
