<template>
  <section class="relative max-w-6xl px-5 py-20 mx-auto text-gray-600 md:px-0">
    <div class="w-full mx-auto mb-10 md:w-3/5">
      <h1 class="mb-2 text-2xl font-bold text-gray-900 sm:text-3xl title-font">
        {{ $t("book-an-appointment") }}
      </h1>
      <div class="w-20 h-1 rounded bg-primary-green"></div>
    </div>
    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit">
        <div class="mx-auto md:w-3/5">
          <div class="flex flex-wrap">
            <div class="w-full p-2 sm:w-1/2">
              <div class="relative">
                <label for="fName" class="leading-8 text-gray-600">
                  {{ $t("first-name") }}
                </label>
                <ValidationProvider
                  name="First name"
                  rules="required|alpha"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    id="fName"
                    v-model="fName"
                    name="firstName"
                    :placeholder="$t('first-name')"
                    class="w-full px-3 py-1 text-sm leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded-sm outline-none focus:border-blue-500 focus:bg-white"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="w-full p-2 sm:w-1/2">
              <div class="relative">
                <label for="lName" class="leading-8 text-gray-600">
                  {{ $t("last-name") }}
                </label>
                <ValidationProvider
                  name="Last name"
                  rules="required|alpha"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    id="lName"
                    v-model="lName"
                    name="lastName"
                    :placeholder="$t('last-name')"
                    class="w-full px-3 py-1 text-sm leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded-sm outline-none focus:border-blue-500 focus:bg-white"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="w-full p-2 sm:w-1/2">
              <div class="relative">
                <label for="age" class="leading-8 text-gray-600">{{
                  $t("age")
                }}</label>
                <ValidationProvider
                  name="age"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <input
                    type="number"
                    id="Age"
                    v-model="age"
                    name="age"
                    :placeholder="$t('age')"
                    class="w-full px-3 py-1 text-sm leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-blue-500 focus:bg-white"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="w-full p-2 sm:w-1/2">
              <div class="relative">
                <label for="email" class="leading-8 text-gray-600">
                  {{ $t("email") }}
                </label>
                <ValidationProvider
                  name="Email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <input
                    type="email"
                    id="email"
                    v-model="email"
                    name="email"
                    :placeholder="$t('email')"
                    class="w-full px-3 py-1 text-sm leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded-sm outline-none focus:border-blue-500 focus:bg-white"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="w-full p-2 sm:w-1/2">
              <div class="relative">
                <label for="phone" class="leading-8 text-gray-600">
                  {{ $t("phone-number") }}
                </label>
                <ValidationProvider
                  name="Phone number"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="tel"
                    id="phone"
                    v-model="phone"
                    name="phone"
                    :placeholder="$t('phone-number')"
                    class="w-full px-3 py-1 text-sm leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded-sm outline-none focus:border-blue-500 focus:bg-white"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <!-- <div class="w-full p-2">
              <div class="mt-4">
                <span class="font-medium text-gray-700">
                  {{ $t("type-of-appointment") }}
                </span>
                <div class="mt-2">
                  <ValidationProvider
                    name="Type Of Appointment"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label class="inline-flex items-center">
                      <input
                        v-model="appointmentType"
                        type="radio"
                        class="w-4 h-4 form-radio"
                        name="appointmentType"
                        value="new"
                      />
                      <span class="ml-2 text-sm">{{
                        $t("new-appointment")
                      }}</span>
                    </label>
                    <label class="inline-flex items-center ml-6">
                      <input
                        v-model="appointmentType"
                        type="radio"
                        class="w-4 h-4 form-radio"
                        name="Appointment Type"
                        value="followUp"
                      />
                      <span class="ml-2 text-sm"> {{ $t("follow-up") }}</span>
                    </label>
                    <div class="text-sm text-red-600">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
            </div> -->
            <div class="w-full mb-10"></div>
            <div class="w-full p-2 sm:w-1/2">
              <div class="relative">
                <label for="department" class="leading-8 text-gray-600">
                  {{ $t("departments") }}
                </label>
                <ValidationProvider
                  name="Departments"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select
                    name="departments"
                    v-model="department"
                    id="departments"
                    class="w-full px-3 py-1 text-sm leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded-sm outline-none focus:border-blue-500 focus:bg-white"
                  >
                    <option value="">Select Department</option>
                    <option value="all">All</option>
                    <option
                      v-for="(department, i) in departments"
                      :key="i"
                      :value="department"
                    >
                      {{ department.deptName }}
                    </option>
                  </select>
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="w-full p-2 sm:w-1/2">
              <div class="relative">
                <label for="doctors" class="leading-8 text-gray-600">
                  {{ $t("doctors") }}
                </label>
                <ValidationProvider
                  name="Doctors"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select
                    :disabled="disabled"
                    name="doctors"
                    id="doctors"
                    v-model="doctor"
                    class="w-full px-3 py-1 text-sm leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded-sm outline-none focus:border-blue-500 focus:bg-white"
                  >
                    <option value="">{{ $t("select-doctor") }}</option>
                    <option
                      v-for="(doctor, i) in selectedDoctors"
                      :key="i"
                      :value="doctor"
                    >
                      {{ doctor.firstName }} {{ doctor.lastName }}
                    </option>
                  </select>
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="w-full p-2 sm:w-1/2">
              <div class="relative">
                <label for="date" class="leading-8 text-gray-600">
                  {{ $t("appointment-date") }}
                </label>
                <VueCtkDateTimePicker
                  v-model="due"
                  :label="$t('select-date')"
                  :only-date="true"
                  format="YYYY-MM-DD"
                  formatted="YYYY-MM-DD"
                  :enabledDates="availableDates"
                  right
                />
              </div>
            </div>
            <div class="w-full p-2 sm:w-1/2">
              <div class="relative">
                <label for="time" class="leading-8 text-gray-600">
                  {{ $t("time") }}
                </label>
                <ValidationProvider
                  name="Time"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select
                    name="time"
                    id="time"
                    v-model="time"
                    class="w-full px-3 py-1 text-sm leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded-sm outline-none focus:border-blue-500 focus:bg-white"
                  >
                    <option value="">{{ $t("select-time") }}</option>
                    <option
                      v-for="(working_hour, index) in availableHours"
                      :key="index"
                      :value="working_hour"
                    >
                      {{ working_hour.hour }} : {{ working_hour.minute }}
                    </option>
                  </select>
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="w-full p-2">
              <div class="relative">
                <label for="message" class="leading-8 text-gray-600">
                  {{ $t("reason-for-your-appointment") }}
                </label>
                <ValidationProvider
                  name="Reason for your visit"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <textarea
                    id="message"
                    v-model="message"
                    name="message"
                    :placeholder="$t('reason-for-your-appointment')"
                    class="w-full h-32 px-3 py-1 text-sm leading-6 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded-sm outline-none resize-none focus:border-blue-500 focus:bg-white"
                  >
                  </textarea>
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="w-full p-2">
              <button
                :loading="loading"
                type="submit"
                class="flex items-center justify-center px-6 py-3 mx-auto text-white transition-colors duration-200 ease-in-out border-0 rounded-sm md:justify-center bg-axon-blue focus:outline-none hover:opacity-80"
              >
                {{ $t("nav.make-appointment") }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "Appointment",
  components: {},
  data() {
    return {
      disabled: true,
      departments: [],
      allDoctors: [],
      selectedDoctors: [],
      workingHours: [],
      valid: true,
      fName: "",
      lName: "",
      phone: "",
      age: null,
      doctor: "",
      department: "",
      due: "",
      time: "",
      email: "",
      date: new Date(),
      loading: false,
      appointmentType: "",
      formAppointments: ["Video Call", "In Person"],
      formOfAppointment: "",
      availableDates: [],
      availableHours: [],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 30) || "Name must be less than 30 characters",
      ],

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      message: "",
      messageRules: [
        (v) => !!v || "Message is required",
        (v) =>
          (v && v.length >= 10) || "Message must be more than 10 characters",
      ],
      snackbar: false,
      snackbar_message: "",
    };
  },
  async mounted() {
    try {
      const departmentsData = await axios.get(
        "https://www.ethiopiastroke.com/api/department/available-departments"
      );
      this.departments = departmentsData.data.departments;
      // const doctors = await axios.get(this.apiDo);
      console.log(this.departments);
      const doctors = await axios.get(
        "https://www.ethiopiastroke.com/api/doctor/available-doctors"
      );
      this.allDoctors = doctors.data;
      console.log(this.allDoctors);

      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
  watch: {
    department: function () {
      this.disabled = this.department === null;
      this.selectedDoctors = this.department
        ? this.department === "all"
          ? this.allDoctors
          : this.allDoctors.filter((doc) => {
              return doc.department === this.department._id;
            })
        : [];
    },

    doctor: async function () {
      this.disableDate = true;
      let datesData = await axios.get(
        `https://www.ethiopiastroke.com/api/doctor/${this.doctor._id}/available-dates`
      );
      this.availableDates = datesData.data.availableDates;
      console.log(this.availableDates);
      this.disableDate = false;
    },
    due: async function () {
      console.log(new Date(this.due));
      let hoursData = await axios.get(
        `https://www.ethiopiastroke.com/api/doctor/${this.doctor._id}/available-hours?date=${this.due}`
      );
      this.availableHours = hoursData.data.available_hours;
      console.log(this.availableHours);
    },
  },
  methods: {
    showToast() {
      this.$toast.success("Appointment sent sucessfully");
    },
    async onSubmit(e) {
      const success = await this.$refs.form.validate();
      try {
        if (success) {
          axios
            .post("https://www.ethiopiastroke.com/api/appointment", {
              patientFirstName: this.fName,
              patientLastName: this.lName,
              patientPhone: this.phone,
              patientAge: parseInt(this.age),
              // appointmentType: this.appointmentType,
              doctor: this.doctor._id,
              department: this.department._id,
              appointmentDate: this.due,
              appointmentTime: this.time,
              patientEmail: this.email,
              reasonForVisit: this.message,
            })
            .then(function (response) {
              console.log(response);
            })
            .catch(function (error) {
              console.log(error);
            });
          this.$nextTick(() => {
            this.$refs.form.reset();
            this.doctor = "";
            this.departments = "";
            this.appointmentTime = "";
            this.fName = "";
            this.lName = "";
            this.phone = "";
            this.appointmentType = "";
            this.age = "";
            this.email = "";
            this.message = "";
            this.showToast();
          });
        } else {
          this.error = true;
          // console.log("Coudln't post mail");
        }
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      // this.name = "";
      // this.email = "";
      // this.message = "";
    },
  },
};
</script>

<style></style>
