<template>
  <div class="py-20">
    <div class="flex flex-wrap w-full mb-14">
      <div class="w-full mb-6 lg:w-1/2 lg:mb-0">
        <h1 class="mb-2 text-5xl font-black text-axon-blue">
          {{ $t("consultants") }}
        </h1>
        <div class="w-20 h-1 rounded bg-primary-green"></div>
      </div>
    </div>
    <div
      class="flex flex-wrap mb-16"
      v-for="consultants in items"
      :key="consultants.id"
    >
      <div>
        <img
          :src="getBaseUrl() + consultants.image.url"
          alt=""
          class="object-cover mb-6 rounded-lg md:mb-0 md:float-left md:mr-6 md:h-72 w-96 md:w-64"
        />
        <div>
          <h2 class="text-3xl font-bold text-gray-900">
            {{ consultants.name }}
          </h2>
          <h3 class="mb-3 font-semibold">
            {{ consultants.specialization }}
          </h3>
          <div class="mb-4 leading-relaxed tracking-wide text-gray-600">
            <VueShowdown
              :markdown="
                consultants.profile !== undefined ? consultants.profile : ''
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "Doctors",
  data() {
    return {
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiConsultants",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        let sorted = response.data.sort((a, b) => a.rank - b.rank);
        this.items = sorted;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
