<template>
  <section class="max-w-6xl mx-auto text-gray-600">
    <div v-if="!loading">
      <div class="px-5 py-20 mx-auto">
        <div v-for="news in items" :key="news.id">
          <SingleNewsDisplay :news="news" :baseUrl="getBaseUrl()" />
        </div>
      </div>
    </div>
    <div v-else>
      <img :src="loadingImage" class="w-64 m-auto" />
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import SingleNewsDisplay from "../components/SingleNewsDisplay.vue";
import { mapGetters } from "vuex";

export default {
  name: "News",
  components: { SingleNewsDisplay },
  data() {
    return {
      items: [],
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      apiLatestNews: "LanguageStore/apiLatestNews",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },

    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    async getData() {
      try {
        const response = await axios.get(this.apiLatestNews);
        let sorted = response.data
          .slice()
          .sort(
            (a, b) => new Date(b.publishedDate) - new Date(a.publishedDate)
          );
        // let sorted = response.data.sort(
        //   (a, b) => Date.parse(b) - Date.parse(a)
        // );
        this.items = sorted;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
