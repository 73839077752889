<template>
  <section>
    <div
      class="hero"
      :style="{
        backgroundImage: 'url(' + getBaseUrl() + definition.image.url + ')',
        'object fit': 'cover',
        'background-repeat': 'no-repeat',
        'z-index': '2',
        'background-size': 'cover',
        'background-position': 'center',
        position: 'relative',
      }"
    >
      <div
        class="left-0 flex flex-col items-center justify-center h-full text-gray-200 bg-black bg-opacity-40"
      >
        <div class="p-4 mx-8 text-center">
          <p class="text-2xl font-bold text-gray-200 md:text-5xl">
            {{ definition.title }}
          </p>
        </div>
      </div>
    </div>
    <div class="container max-w-4xl px-4 py-16 mx-auto">
      <h1 class="mb-4 text-4xl font-black text-gray-800">
        {{ definition.subtitle }}
      </h1>
      <p class="leading-relaxed tracking-wide text-gray-600">
        <VueShowdown :markdown="`${definition.mainContent}`" />
      </p>
    </div>
    <div
      class="container max-w-4xl px-4 py-8 mx-auto mb-10 tracking-wide text-gray-600 bg-gray-50"
    >
      <p class="mb-4">
        <VueShowdown :markdown="`${definition.subContent}`" />
      </p>
      <router-link
        to="types-of-stroke"
        class="px-3 py-2 mt-4 text-white rounded hover:opacity-80 bg-axon-red"
      >
        Find out more
      </router-link>
    </div>
  </section>
</template>

<script>
import { baseUrl } from "../Api";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "Definition",
  data() {
    return {
      definition: {},
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiDefinition",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.definition = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss" scoped>
.hero {
  height: 500px;
}
</style>
