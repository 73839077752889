<template>
  <section class="max-w-6xl mx-auto text-gray-600">
    <div v-if="!loading">
      <div class="px-5 py-20">
        <div
          class="mb-24 rounded-lg"
          v-for="(about, index) in items"
          :key="about.id"
          :class="
            index % 2 === 0 ? 'bg-blue-50 py-20 px-6' : 'bg-gray-100 py-20 px-6'
          "
        >
          <div class="grid">
            <div>
              <img
                :src="getBaseUrl() + about.Image.url"
                alt=""
                class="object-cover mb-6 rounded-lg md:mb-0 md:float-left md:mr-6 md:h-72 w-96 md:w-56"
              />
              <div>
                <h2 class="text-3xl font-bold text-gray-900">
                  {{ about.Name }}
                </h2>
                <h3 class="mb-3 font-semibold">
                  {{ about.Specialization }}
                </h3>
                <div class="mb-4 text-gray-600">
                  <VueShowdown
                    :markdown="about.Profile !== undefined ? about.Profile : ''"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <img :src="loadingImage" class="w-64 m-auto" />
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "About",
  components: {},
  data() {
    return {
      Wondwossen: require("../assets/images/hero/Wondwossen.jpeg"),
      items: [],
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiAbouts",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.items = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>
