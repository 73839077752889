<template>
  <section class="max-w-6xl mx-auto text-gray-600">
    <div class="px-5 py-20">
      <div class="flex flex-wrap w-full mb-10">
        <div class="w-full mb-6 lg:w-1/2 lg:mb-0">
          <h1 class="mb-4 text-3xl font-black text-axon-blue">
            {{ $t("services") }}
          </h1>
          <div class="w-20 h-1 rounded bg-primary-green"></div>
        </div>
      </div>
      <div v-if="!loading">
        <div
          class="grid grid-cols-1 gap-8 pb-20 mx-auto sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2"
        >
          <div
            class="overflow-hidden text-gray-600 bg-gray-100 rounded-lg"
            v-for="department in items"
            :key="department.id"
          >
            <div class="relative pb-2/3">
              <img
                class="absolute object-cover object-center w-full h-full"
                :src="getBaseUrl() + department.departmentImage.url"
                alt=""
              />
            </div>
            <div class="px-4 py-4">
              <div class="mb-2 text-xl font-bold">
                {{ department.title }}
              </div>
              <p
                class="leading-relaxed tracking-wide text-gray-600"
                :class="{
                  'line-clamp-5':
                    department.departmentContent.trim().split(/\s+/).length > 36
                      ? true
                      : false,
                }"
              >
                <VueShowdown :markdown="department.departmentContent" />
              </p>
              <router-link
                v-if="
                  department.departmentContent.trim().split(/\s+/).length > 36
                "
                @click.native="scrollToTop"
                :to="{
                  name: 'SelectedDepartmentDetail',
                  params: {
                    department: department,
                    id: department.id,
                  },
                }"
                class="inline-flex items-center mt-3 text-sm transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-axon-red hover:border-axon-red"
              >
                ... {{ $t("read-more") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <img :src="loadingImage" class="w-64 m-auto" />
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "Departments",
  data() {
    return {
      items: [],
      more: false,
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiDepartments",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.items = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
