<template>
  <div v-if="!loading">
    <div class="max-w-6xl px-5 py-20 mx-auto">
      <div class="flex flex-col pb-10 mx-auto mb-10">
        <div
          class="relative inline-flex justify-center flex-shrink-0 pb-3/5 md:pb-1/3"
        >
          <img
            class="absolute object-cover w-full h-full rounded-lg"
            :src="getBaseUrl() + department.departmentImage.url"
            alt=""
          />
        </div>
        <div class="flex-grow mt-6 sm:text-left">
          <div class="mb-2 text-2xl font-semibold">
            {{ department.title }}
          </div>
          <p class="mt-3 tracking-wide text-gray-600">
            <VueShowdown :markdown="department.departmentContent" />
          </p>
        </div>
      </div>
      <div class="px-3 pt-6 pb-3 overflow-y-auto bg-gray-300 rounded-lg">
        <h1
          v-if="department.title === 'Neurointerventional Surgery'"
          class="pb-6 text-2xl font-bold text-center"
        >
          Stroke and Neurointerventional Procedures
        </h1>
        <h1
          v-if="department.title === 'Interventional Pain Management'"
          class="pb-6 text-2xl font-bold text-center"
        >
          Interventional Pain Management Procedures and Indications
        </h1>
        <table
          class="w-full tracking-wide text-gray-600 bg-white border border-collapse list border-slate-400"
        >
          <thead>
            <tr>
              <th class="p-4 border bg-gray-50 border-slate-300">
                {{ $t("procedure-name") }}
              </th>
              <th class="p-4 border bg-gray-50 border-slate-300">
                {{ $t("indication-for-procedure") }}
              </th>
              <th
                v-if="department.DepartmentProcedures[0].Description"
                class="p-4 border bg-gray-50 border-slate-300"
              >
                {{ $t("brief-description-of-procedure-and-goals") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="procedure in department.DepartmentProcedures"
              :key="procedure.id"
            >
              <td class="p-4 border border-slate-300">
                {{ procedure.ProcedureName }}
              </td>
              <td class="p-4 border border-slate-300">
                <VueShowdown :markdown="procedure.Indication" />
              </td>
              <td
                v-if="procedure.Description"
                class="p-4 border border-slate-300"
              >
                <VueShowdown :markdown="procedure.Description" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div v-else>
    <img :src="loadingImage" class="w-64 m-auto" />
  </div>
</template>

<script>
import { baseUrl } from "../Api";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "SelectedDepartmentDetail",
  data() {
    return {
      loading: true,
      news: {},
      loadingImage: require("../assets/curve-Loading.gif"),
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiDepartments",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      if (this.$route.params.department === undefined) {
        try {
          const response = await axios.get(this.api);
          this.department = response.data.find(
            (department) => department.id === this.$route.params.id
          );
          this.loading = false;
        } catch (error) {
          this.error = error;
        }
      } else {
        this.department = this.$route.params.department;
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
