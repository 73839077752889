var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"hero",style:({
      backgroundImage: 'url(' + _vm.getBaseUrl() + _vm.definition.image.url + ')',
      'object fit': 'cover',
      'background-repeat': 'no-repeat',
      'z-index': '2',
      'background-size': 'cover',
      'background-position': 'center',
      position: 'relative',
    })},[_c('div',{staticClass:"left-0 flex flex-col items-center justify-center h-full text-gray-200 bg-black bg-opacity-40"},[_c('div',{staticClass:"p-4 mx-8 text-center"},[_c('p',{staticClass:"text-2xl font-bold text-gray-200 md:text-5xl"},[_vm._v(" "+_vm._s(_vm.definition.title)+" ")])])])]),_c('div',{staticClass:"container max-w-4xl px-4 py-16 mx-auto"},[_c('h1',{staticClass:"mb-4 text-4xl font-black text-gray-800"},[_vm._v(" "+_vm._s(_vm.definition.subtitle)+" ")]),_c('p',{staticClass:"leading-relaxed tracking-wide text-gray-600"},[_c('VueShowdown',{attrs:{"markdown":("" + (_vm.definition.mainContent))}})],1)]),_c('div',{staticClass:"container max-w-4xl px-4 py-8 mx-auto mb-10 tracking-wide text-gray-600 bg-gray-50"},[_c('p',{staticClass:"mb-4"},[_c('VueShowdown',{attrs:{"markdown":("" + (_vm.definition.subContent))}})],1),_c('router-link',{staticClass:"px-3 py-2 mt-4 text-white rounded hover:opacity-80 bg-axon-red",attrs:{"to":"types-of-stroke"}},[_vm._v(" Find out more ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }