<template>
  <div class="bg-gray-100 py-14">
    <div class="max-w-6xl px-5 mx-auto text-gray-600">
      <div class="w-full mb-10 lg:w-1/2">
        <h1 class="mb-4 text-3xl font-black text-axon-blue">
          {{ $t("speciality-services") }}
        </h1>
      </div>
      <div
        class="grid grid-cols-1 gap-8 pb-20 mx-auto sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
      >
        <div
          class="overflow-hidden text-gray-600 bg-white rounded-lg"
          v-for="speciality in speciality"
          :key="speciality.id"
        >
          <img
            class="object-cover object-center w-full h-64"
            v-if="speciality.Image"
            :src="getBaseUrl() + speciality.Image.url"
            :alt="speciality.name"
          />
          <div class="px-4 py-4">
            <div class="mb-2 text-xl font-bold text-axon-blue">
              {{ speciality.name }}
            </div>
            <p class="text-sm leading-relaxed tracking-wide text-gray-600">
              <VueShowdown :markdown="`${speciality.Content}`" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "Speciality",
  data() {
    return {
      speciality: {},
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiSpeciality",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    async getData() {
      try {
        const speciality = await axios.get(this.api);
        this.speciality = speciality.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style scoped></style>
