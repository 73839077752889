<template>
  <div>
    <Hero></Hero>
    <Intro></Intro>
    <Speciality></Speciality>
    <History></History>
    <Mission></Mission>
    <Affiliate></Affiliate>
    <LatestNews></LatestNews>
  </div>
</template>

<script>
import Hero from "../components/Hero.vue";
import Intro from "../components/Intro.vue";
import History from "../components/History.vue";
import Speciality from "../components/SpecialitiesAndServices.vue";
import Mission from "../components/Mission.vue";
import Affiliate from "../components/Affiliate.vue";
import LatestNews from "../components/LatestNews.vue";

// @ is an alias to /src

export default {
  name: "Home",
  components: {
    Intro,
    Hero,
    History,
    Speciality,
    Affiliate,
    Mission,
    LatestNews,
  },
};
</script>
<style></style>
