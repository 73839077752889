<template>
  <div class="max-w-6xl mx-auto text-gray-600">
    <section class="px-5 py-20">
      <div class="w-full mb-10 lg:w-1/2">
        <h1 class="mb-4 text-3xl font-black text-axon-blue">
          {{ $t("our-mission") }}
        </h1>
      </div>
      <div class="flex flex-col order-1 mb-6">
        <div>
          <p class="mb-8 leading-relaxed tracking-wide">
            <VueShowdown :markdown="`${mission.Content}`" />
          </p>
        </div>
      </div>
      <div class="w-full lg:max-w-lg">
        <img
          class="object-cover object-center rounded-lg"
          :alt="mission.Title"
          :src="getBaseUrl() + mission.Image.url"
        />
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "Mission",
  data() {
    return {
      mission: {},
      loading: true,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiMission",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    async getData() {
      try {
        const mission = await axios.get(this.api);
        this.mission = mission.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
