import { baseUrl } from "@/Api";
export default {
    namespaced: true,
    state: {
        lang: localStorage.getItem("lang") || "en",
    },
    mutations: {
        SET_LANG(state, payload) {
            state.lang = payload;
        },
    },
    actions: {
        doChangeLanguage({ commit, state }, payload) {
            // state change + localstorage
            commit("SET_LANG", payload);
        },
    },
    getters: {
        lang(state) {
            return state.lang;
        },
        apiDoctors(state) {
            return `${baseUrl}/doctors?_locale=${state.lang}`;
        },
        apiConsultants(state) {
            return `${baseUrl}/consultants?_locale=${state.lang}`;
        },
        apiDepartments(state) {
            return `${baseUrl}/departments?_locale=${state.lang}`;
        },
        apiIntros(state) {
            return `${baseUrl}/intros?_locale=${state.lang}`;
        },

        apiHeros(state) {
            return `${baseUrl}/slider-images?_locale=${state.lang}`;
        },
        apiDefinition(state) {
            return `${baseUrl}/stroke-definition?_locale=${state.lang}`;
        },
        apiTypes(state) {
            return `${baseUrl}/stroke-types?_locale=${state.lang}`;
        },
        apiSymptoms(state) {
            return `${baseUrl}/stroke-symptoms?_locale=${state.lang}`;
        },
        apiRisks(state) {
            return `${baseUrl}/stroke-risk?_locale=${state.lang}`;
        },
        apiAbouts(state) {
            return `${baseUrl}/abouts?_locale=${state.lang}`;
        },
        apiVissions(state) {
            return `${baseUrl}/vissions?_locale=${state.lang}`;
        },
        apiMission(state) {
            return `${baseUrl}/mission?_locale=${state.lang}`;
        },
        apiSpeciality(state) {
            return `${baseUrl}/specialities-and-services?_locale=${state.lang}`;
        },
        apiHistory(state) {
            return `${baseUrl}/history?_locale=${state.lang}`;
        },
        // apiVideos(state) {
        //   return `${baseUrl}/videos?_locale=${state.lang}`;
        // },
        apiFooters(state) {
            return `${baseUrl}/footers?_locale=${state.lang}`;
        },
        apiLatestNews(state) {
            return `${baseUrl}/latest-news?_locale=${state.lang}`;
        },
        // apiSocials(state) {
        //   return `${baseUrl}/socials?_locale=${state.lang}`;
        // },
        // apiImprint(state) {
        //   return `${baseUrl}/imprints?_locale=${state.lang}`;
        // },
        // apiPrivacies(state) {
        //   return `${baseUrl}/privacies?_locale=${state.lang}`;
        // },
    },
};
